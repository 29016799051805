<template>
  <div>
    <app-table-registers :getList="getList" :sm="false" ref="tr">
      <template slot="tl">
        <div class="col"></div>
      </template>
      <template slot="table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Detalle</th>
            <th>Por pagar</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, index) in list.data" :key="index">
            <td>
              <router-link :to="`/dental-clinic/laboratories/${l.id}`">
                {{ l.name }}
              </router-link>
            </td>
            <td></td>
            <td>
              <app-span-money
                :moneyCode="1"
                :quantity="l.amount_to_pay"
              ></app-span-money>
            </td>
            <td>
              <app-switch
                :value="l.enable"
                @input="
                  l.enable = $event;
                  changeState(l);
                "
              >
                Habilitado</app-switch
              >
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>
  </div>
</template>
<script>
import { LogisticService } from "../../logistic-module/service";
import { mapState } from "vuex";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    enables: { default: 1 }
  },

  data: () => ({
    list: {}
  }),

  computed: {
    ...mapState({
      categorie_id: s => s.config.dental.laboratory.categorie_id
    })
  },

  watch: {
    $route() {
      this.$refs.tr.gl();
    }
  },

  mounted() {
    //
  },

  methods: {
    changeState(l) {
      LogisticService.saveSupplier(l);
    },
    getList({ page, search }) {
      return new Promise(rsv => {
        LogisticService.getSuppliers({
          categorie_id: this.categorie_id,
          page,
          search,
          enables: this.enables
        }).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
